const addDataToConfig = (data, configFields) => {
  let result = configFields;

  if (data && Object.keys(data).length) {
    result = configFields.map((fieldsRow) =>
      fieldsRow.map((field) => {
        const keyParts = field.key.split('-');
        let mappedField = {};

        if (keyParts.length === 2) {
          mappedField = {
            ...field,
            value: data[keyParts[0]][keyParts[1]],
          };
        } else {
          mappedField = {
            ...field,
            value: data[keyParts[0]],
          };
        }

        return mappedField;
      }),
    );
  }

  return result;
};

const parseRequestData = (data, parentKey) =>
  Object.keys(data).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [`${parentKey}-${key}`]: data[key],
    }),
    {},
  );

const parseDataToConfig = (data) => {
  const { partner, contactInfo } = data;

  return {
    ...parseRequestData(partner, 'partner'),
    ...parseRequestData(contactInfo, 'contactInfo'),
  };
};

const buildCdrData = (data) => {
  const cdrData = {
    contactInfo: {},
    partner: {},
  };

  Object.keys(data).forEach((key) => {
    const keyParts = key.split('-');

    if (keyParts.length === 2) {
      const dataParentKey = keyParts[0];
      const dataKey = keyParts[1];

      if (dataParentKey === 'contactInfo' || dataParentKey === 'partner') {
        cdrData[dataParentKey] = {
          ...cdrData[dataParentKey],
          [dataKey]: data[key],
        };
      }
    }
  });

  return cdrData;
};

export { addDataToConfig, parseDataToConfig, buildCdrData };
