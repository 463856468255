// @flow

import React from 'react';
import { styled, Typography } from '@mui/material';
import LogoArrowWorm from 'images/Arrow_Worm_black.png';
import Vector from 'images/Vector.png';
// $FlowFixMe
import { useIntl } from 'react-intl';
import Snack from 'components/Snack';

const styles = {
  container: {
    height: 'calc(100vh - 330px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrowLogo: {
    backgroundImage: `url(${LogoArrowWorm})`,
    width: 399,
    height: 180,
  },
  vectorLogo: {
    backgroundImage: `url(${Vector})`,
    width: 66,
    height: 66,
  },
  text: {
    fontSize: 17,
    lineHeight: '22px',
    marginBottom: '24px',
  },
  textDone: {
    fontWeight: 'bold',
  },
};

type Props = {
  currentConfigCount: number,
  countConfig: ?number,
  countAnalytics: number,
};

const Container = styled('div')(() => styles.container);
const ArrowLogo = styled('div')(({ theme }) => ({
  ...styles.arrowLogo,
  marginBottom: theme.spacing(7),
}));
const VectorLogo = styled('div')(({ theme }) => ({
  ...styles.vectorLogo,
  marginBottom: theme.spacing(7),
}));

const ReviewProgress = ({
  currentConfigCount,
  countConfig,
  countAnalytics,
}: Props): React$Element<any> => {
  const intl = useIntl();

  return (
    <Container>
      <Snack />

      <ArrowLogo role="img" />

      <VectorLogo role="img" />

      <Typography role="article" variant="inherit" sx={styles.text}>
        {intl.formatMessage({
          id: 'reviewProgress.uploadingInProgress',
          defaultMessage: 'Upload in progress ...',
        })}
      </Typography>

      {countConfig && (
        <Typography
          data-testid="full-configurations-article"
          role="article"
          variant="inherit"
          sx={styles.text}
        >
          {intl.formatMessage({
            id: 'reviewProgress.fullConfigurations',
            defaultMessage: 'Full Configurations ... 10/15',
          })}{' '}
          {`${currentConfigCount}/${countConfig}`}
        </Typography>
      )}

      <Typography role="article" variant="inherit" sx={styles.text}>
        {intl.formatMessage({
          id: 'reviewProgress.configAnalytics',
          defaultMessage: 'Config Analytics ...',
        })}{' '}
        {countAnalytics}/1
      </Typography>

      <Typography
        role="article"
        variant="inherit"
        sx={{ ...styles.text, ...styles.textDone }}
      >
        {intl.formatMessage({
          id: 'reviewProgress.done',
          defaultMessage: 'Done !',
        })}
      </Typography>
    </Container>
  );
};

export default ReviewProgress;
