// @flow

import { useState, useEffect } from 'react';
// $FlowFixMe
import axios from 'axios';
import localforage from 'localforage';
import { LF_CDR_KEY } from 'constants/globalConsts';

const { REACT_APP_API_URL } = process.env;

type CDR = {
  id: string,
  files: Array<Object>,
  gdprChecked: boolean,
};

function useCdr(): [CDR | null, (CDR | null) => void, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [storedValue, setStoredValue] = useState<CDR | null>(null);

  useEffect(() => {
    const getCdr = async () => {
      const { location } = window;
      const regex = /\/config-discovery-request\/([a-z0-9-]+)/;
      const id = location.hash.match(regex);
      if (id && id.length === 2) {
        try {
          const currentCdrId = id[1];
          let cdr = await localforage.getItem(LF_CDR_KEY);
          if (!cdr || cdr.id !== currentCdrId) {
            // $FlowFixMe
            const { data } = await axios.get(
              // $FlowFixMe
              `${REACT_APP_API_URL}/config-discovery-request/${currentCdrId}`,
            );

            localforage.clear();
            cdr = {
              ...data,
              files: data.files || [],
              gdprChecked: false,
            };
            localforage.setItem(LF_CDR_KEY, cdr);
          }
          await setStoredValue(cdr);
          await setIsLoading(false);
        } catch (err) {
          await localforage.removeItem(LF_CDR_KEY);
          await setStoredValue(null);
          await setIsLoading(false);
        }
      } else {
        await localforage.removeItem(LF_CDR_KEY);
        await setStoredValue(null);
        await setIsLoading(false);
      }
    };
    getCdr();
  }, []);

  const setValue = (value: CDR | null) => {
    localforage.setItem(LF_CDR_KEY, value);
    setStoredValue(value);
  };

  return [storedValue, setValue, isLoading];
}

export default useCdr;
