// @flow
/* istanbul ignore file */

import messagesEn from 'translations/en.json';
import messagesFr from 'translations/fr.json';

const messages = {
  en: messagesEn,
  fr: messagesFr,
};

const locale = 'en';
const language = 'en';

export { language, messages, locale };
