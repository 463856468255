// @flow
import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useIntl } from 'react-intl';
// $FlowFixMe
import { Warning as IconWarning } from '@mui/icons-material';
import Separator from 'components/Separator';
import type { ChildProps } from './ModalRoot';

const styles = {
  textLink: {
    textDecoration: 'underline',
    color: '#0069AA',
    fontSize: 16,
    lineHeight: '21px',
    marginTop: (theme: Object) => theme.spacing(2),
  },
  iconWarning: {
    alignSelf: 'center',
    marginRight: (theme: Object) =>
      `calc(${theme.spacing(1)} + ${theme.spacing(1)} / 2)`,
  },
  separator: {
    width: 'calc(100% - 96px)',
    margin: (theme: Object) => `0px ${theme.spacing(7)}`,
  },
};

const ReviewWarning = ({
  handleClose,
  callback,
}: ChildProps): React$Element<any> => {
  const intl = useIntl();

  return (
    <>
      <DialogTitle data-testid="review-warning-modal-title">
        <IconWarning fontSize="small" sx={styles.iconWarning} />
        {intl.formatMessage({
          id: 'modal.reviewWarning.title',
          defaultMessage: 'Warning !',
        })}
      </DialogTitle>
      <Separator styles={styles.separator} />
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({
            id: 'modal.reviewWarning.description',
            defaultMessage:
              'Your choice was not to upload the full configs to the platform. Without these we already come a long way to create a decent quote, however in some cases we might need to request the full configurations to ensure a correct quote.',
          })}
        </DialogContentText>
        <DialogContentText sx={styles.textLink}>
          {intl.formatMessage({
            id: 'modal.reviewWarning.textLink',
            defaultMessage: 'Read more to discover why this is important.',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="disagree-button-modal"
          onClick={handleClose}
          variant="text"
        >
          {intl.formatMessage({
            id: 'modal.reviewWarning.actionGoBack',
            defaultMessage: 'Go back',
          })}
        </Button>
        <Button
          data-testid="agree-button-modal"
          onClick={callback}
          color="primary"
          variant="contained"
          autoFocus
        >
          {intl.formatMessage({
            id: 'modal.reviewWarning.actionSubmit',
            defaultMessage: 'Submit',
          })}
        </Button>
      </DialogActions>
    </>
  );
};

export default ReviewWarning;
