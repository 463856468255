const stripConfigSenesitiveInfo = (originalConfigName, parsedConfig) => {
  const exportLines = [];
  const parsedConfigObjects = parsedConfig.objects;

  Object.keys(parsedConfigObjects).forEach((key) => {
    const objectItems = parsedConfigObjects[key].items;

    objectItems.forEach((item) => {
      exportLines.push({
        line: item.line,
        baseline: item.baseline,
      });
    });
  });

  const result = exportLines
    .sort((a, b) => (a.line > b.line ? 1 : -1))
    .map((a) => `${a.baseline}\r\n`);
  const strippedFile = new File(result, originalConfigName, {
    type: 'text/plain',
  });

  return strippedFile;
};

// eslint-disable-next-line import/prefer-default-export
export { stripConfigSenesitiveInfo };
