// @flow
import localforage from 'localforage';
import React, { useEffect } from 'react';

type TypeSnackOpen = {
  message: string,
  success: boolean,
};
type TypeSnack = {
  ...TypeSnackOpen,
  open: boolean,
};

const initialSnackState: TypeSnack = {
  open: false,
  message: '',
  success: false,
};

const useSnack = (): [TypeSnack, (TypeSnackOpen) => void, Function] => {
  const [state, setState] = React.useState<TypeSnack>(initialSnackState);

  const resetSnack = () => {
    localforage.setItem('snack', initialSnackState);
    setState(initialSnackState);
  };

  const setOpen = (payload: TypeSnackOpen) => {
    const { message, success } = payload;
    const snackData = {
      open: true,
      message,
      success,
    };
    localforage.setItem('snack', snackData).then((data) => setState(data));
  };

  useEffect(() => {
    localforage.getItem('snack').then((data) => {
      if (data) {
        setState(data);
      }
    });
  }, []);

  return [state, setOpen, resetSnack];
};

export default useSnack;
