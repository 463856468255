// @flow

import React from 'react';
import {
  styled,
  Button,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@mui/material';
import { useIntl } from 'react-intl';
// $FlowFixMe
import { useNavigate } from 'react-router-dom';
// $FlowFixMe
import { ArrowRight as IconArrowRight } from '@mui/icons-material';
import ButtonLink from 'components/ButtonLink';
import ReviewAccordionItem from 'components/ReviewAccordionItem';

const styles = {
  container: {},
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: {
    marginRight: (theme: Object) => theme.spacing(2),
  },
  actionPrevious: {
    color: '#000000',
  },
  separator: {
    borderBottom: '1px solid #CCCCCC',
    width: '100%',
  },
  textTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 'bold',
  },
  textReview: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  requestInfoWrapper: {
    height: 'calc(100vh / 1.784313725 - 170px)',
    border: '1px solid #666666',
    borderRadius: '2px',
    overflowY: 'auto',
  },
  generalRoot: {
    display: 'flex',
  },
  checkboxRow: {
    marginBottom: (theme: Object) => theme.spacing(4),
  },
  checkbox: {
    '&.Mui-checked': {
      color: (theme: Object) => theme.palette.secondary.main,
    },
  },
  heading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  generalInfoItem: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '14px',
  },
};

type PropsReviewRequest = {
  onSubmit: Function,
  s3Checked: boolean,
  setS3Checked: Function,
  cdr: Object,
};

const Container = styled('div')(() => styles.container);
const Separator = styled('div')(({ theme }) => ({
  ...styles.separator,
  margin: `${theme.spacing(3)} 0px ${theme.spacing(4)}`,
}));
const RequestInfoWrapper = styled('div')(({ theme }) => ({
  ...styles.requestInfoWrapper,
  margin: `${theme.spacing(3)} 0px`,
}));
const GeneralRoot = styled('div')(({ theme }) => ({
  ...styles.generalRoot,
  paddingLeft: theme.spacing(11),
  '&& > div:nth-of-type(1)': {
    marginRight: theme.spacing(2),
  },
}));
const GeneralContact = styled('div')(() => {});
const FlexRow = styled('div')(() => {});

const ReviewRequest = ({
  onSubmit,
  s3Checked,
  setS3Checked,
  cdr,
}: PropsReviewRequest): React$Element<any> => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Container>
      <Typography variant="inherit" sx={styles.textTitle}>
        {intl.formatMessage({
          id: 'routes.review.review',
          defaultMessage: 'Review',
        })}
      </Typography>

      <Separator />

      <Typography variant="inherit" sx={styles.textReview}>
        {intl.formatMessage({
          id: 'routes.review.reviewInformation',
          defaultMessage:
            'Please review the information that will be collected and sent for quoting purposes :',
        })}
      </Typography>

      <RequestInfoWrapper>
        {cdr.files && cdr.files.length > 0 && (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<IconArrowRight />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={styles.heading}>
                  {intl.formatMessage({
                    id: 'routes.review.generalInformation',
                    defaultMessage: 'General Information',
                  })}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <GeneralRoot>
                  <div>
                    <Typography sx={styles.heading}>
                      Contact Information
                    </Typography>
                    <List>
                      {Object.keys(cdr.contactInfo).map((key) => (
                        <ListItem
                          sx={styles.generalInfoItem}
                          key={`contact_${key}`}
                        >
                          {`${intl.formatMessage({
                            id: `reviewRequest.${key}`,
                            // $FlowFixMe
                          })}: ${cdr.contactInfo[key]}`}
                        </ListItem>
                      ))}
                    </List>
                  </div>

                  <GeneralContact>
                    <Typography sx={styles.heading}>
                      Partner Information
                    </Typography>
                    <List>
                      {Object.keys(cdr.partner)
                        .filter((key) => key !== 'communicateWithEmailOnly')
                        .map((key) => (
                          <ListItem
                            sx={styles.generalInfoItem}
                            key={`partner_${key}`}
                          >
                            {`${intl.formatMessage({
                              id: `reviewRequest.${key}`,
                              // $FlowFixMe
                            })}: ${cdr.partner[key]}`}
                          </ListItem>
                        ))}
                    </List>
                  </GeneralContact>
                </GeneralRoot>
              </AccordionDetails>
            </Accordion>

            {cdr.files &&
              cdr.files.length > 0 &&
              cdr.files.map((file) => (
                <ReviewAccordionItem key={file.id} data={file} />
              ))}
          </>
        )}
      </RequestInfoWrapper>

      <FlexRow sx={{ ...styles.flexRow, ...styles.checkboxRow }}>
        <Checkbox
          sx={styles.checkbox}
          checked={s3Checked}
          onClick={setS3Checked}
        />

        <Typography sx={{ marginRight: '4px' }} variant="inherit">
          {intl.formatMessage({
            id: 'routes.review.s3Text',
            defaultMessage: 'Submit the full configurations to the platform.',
          })}
        </Typography>

        <ButtonLink
          text={intl.formatMessage({
            id: 'routes.review.s3Description',
            defaultMessage: 'Read more to discover the options.',
          })}
        />
      </FlexRow>

      <FlexRow sx={styles.flexRow}>
        <Button
          sx={{ ...styles.actions, ...styles.actionPrevious }}
          variant="outlined"
          color="primary"
          onClick={() =>
            navigate(`/config-discovery-request/${cdr.id}/configs`)
          }
          data-testid="review-request-previous-button"
        >
          {intl.formatMessage({
            id: 'routes.review.actionPrevious',
            defaultMessage: 'Previous',
          })}
        </Button>

        <Button
          sx={styles.actions}
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          {intl.formatMessage({
            id: 'routes.review.actionSubmit',
            defaultMessage: 'Submit',
          })}
        </Button>
      </FlexRow>
    </Container>
  );
};

export default ReviewRequest;
