// @flow
/* istanbul ignore file */

import { styled, Typography } from '@mui/material';
import Layout from 'components/Layout';
import { LAYOUT_BODY } from 'constants/globalConsts';
import React from 'react';
// $FlowFixMe
import { useIntl } from 'react-intl';

const styles = {
  container: {
    height: '100%',
    width: '100vh',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const Container = styled('div')(() => styles.container);

const NotFound = (): React$Element<any> => {
  const intl = useIntl();

  return (
    <Layout body={LAYOUT_BODY.CENTERED}>
      <Container data-testid="not-found">
        <Typography variant="h3" component="h1" gutterBottom>
          {intl.formatMessage({
            id: '404',
            defaultMessage: 'Not found',
          })}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {intl.formatMessage({
            id: 'route.notFound.description',
            defaultMessage:
              'Sorry, the page you were trying to view does not exist.',
          })}
        </Typography>
      </Container>
    </Layout>
  );
};

export default NotFound;
