// @flow
/* istanbul ignore file */
import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';
// $FlowFixMe
import DeleteIcon from '@mui/icons-material/Delete';
import {
  adcFormFactorKey,
  adcLicenseKey,
  getAdcFormFactorSelectConfig,
  getAdcLicenseSelectConfig,
  getConfigsTableColumns,
  instanceNameInputConfig,
  instanceNameKey,
} from 'constants/configsListConsts';
import { useIntl } from 'react-intl';
import ConfigStatus from 'components/ConfigStatus';
import FormFieldText from 'components/FormFieldText';
import FormFieldSelect from 'components/FormFieldSelect';
import { FILE_STATUS } from 'constants/globalConsts';
import { Analysis } from 'xpand-components';

const styles = {
  configsTableCol: {
    width: '240px',
    paddingLeft: 0,
    paddingRight: '32px',
  },
  configsTableColSmall: {
    width: '90px',
    paddingLeft: 0,
    paddingRight: 0,
  },
  configsTableColLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
  },
  configsTableRow: {
    borderTop: '1px solid #CCCCCC',
    borderBottom: '1px solid #CCCCCC',
    height: '72px',
  },
  configsTableRowCell: {
    padding: '0 32px 0 0',
    lineHeight: '17px',
    fontWeight: 400,
    color: '#000000',
    fontSize: '13px',
  },
  configsTableRowEndCell: {
    padding: '0',
  },
  deleteIcon: {
    color: '#CCCCCC',
  },
  textFormField: {
    margin: '0',
  },
  selectPlaceholder: {
    lineHeight: '17px',
    fontWeight: 400,
    color: '#757575',
    fontSize: '13px',
  },
  textFieldInput: {
    lineHeight: '17px',
    fontWeight: 400,
    fontSize: '13px',
  },
};

type DataObject = {
  analysis?: Object,
  fileName: string,
  id: string,
  instanceName?: string,
  adcFormFactor?: string,
  adcLicense?: string,
  status: string,
  invalidMessage?: string,
};

type Props = {
  data: Array<DataObject>,
  deleteConfig: Function,
  changeConfigData: Function,
};

const ConfigsList = ({
  data,
  deleteConfig,
  changeConfigData,
}: Props): React$Element<any> => {
  const intl = useIntl();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {getConfigsTableColumns(intl).map((col) => (
              <TableCell key={col.key} sx={styles[col.className]}>
                <Typography variant="inherit" sx={styles.configsTableColLabel}>
                  {col.required ? `${col.label} *` : col.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const controlDisabled = row.status !== FILE_STATUS.FINISHED;
            let statusText = row.status;
            if (row.status === FILE_STATUS.FINISHED) {
              statusText =
                row.analysis && row.analysis.stats && row.analysis.stats.ha
                  ? 'HA pair'
                  : 'Standalone';
            } else if (row.status === FILE_STATUS.INVALID) {
              statusText = 'invalid';
            }

            return (
              <TableRow key={row.id} sx={styles.configsTableRow}>
                <TableCell sx={styles.configsTableRowCell}>
                  <ConfigStatus
                    status={row.status}
                    text={statusText}
                    tooltipText={row.invalidMessage}
                  />
                </TableCell>
                <TableCell sx={styles.configsTableRowCell}>
                  {row.fileName}
                </TableCell>
                <TableCell sx={styles.configsTableRowCell}>
                  <FormFieldText
                    testId="instance-pair-name"
                    field={instanceNameInputConfig}
                    onChange={(e) =>
                      changeConfigData(row.id, instanceNameKey, e.target.value)
                    }
                    value={row.instanceName}
                    styles={styles}
                    error={!controlDisabled && !row.instanceName}
                    fullWidth
                    disabled={controlDisabled}
                  />
                </TableCell>
                <TableCell sx={styles.configsTableRowCell}>
                  <FormFieldSelect
                    testId="adc-form-factor"
                    field={getAdcFormFactorSelectConfig(intl)}
                    onChange={(e) =>
                      changeConfigData(row.id, adcFormFactorKey, e.target.value)
                    }
                    value={row.adcFormFactor || ''}
                    styles={styles}
                    renderLabel={false}
                    error={!controlDisabled && !row.adcFormFactor}
                    fullWidth
                    disabled={controlDisabled}
                  />
                </TableCell>
                <TableCell sx={styles.configsTableRowCell}>
                  <FormFieldSelect
                    testId="adc-license"
                    field={getAdcLicenseSelectConfig(intl)}
                    onChange={(e) =>
                      changeConfigData(row.id, adcLicenseKey, e.target.value)
                    }
                    value={row.adcLicense || ''}
                    styles={styles}
                    renderLabel={false}
                    error={!controlDisabled && !row.adcLicense}
                    fullWidth
                    disabled={controlDisabled}
                  />
                </TableCell>
                <TableCell sx={styles.configsTableRowCell}>
                  <Analysis
                    instance={`${row.instanceName || ''} ${intl.formatMessage({
                      id: 'analysis.title',
                      defaultMessage: 'Analysis',
                    })}`}
                    analysisItems={
                      row && row.analysis ? row.analysis.analysisItems : []
                    }
                    disabled={controlDisabled}
                  />
                </TableCell>
                <TableCell align="right" sx={styles.configsTableRowEndCell}>
                  <IconButton
                    data-testid="delete-icon-button"
                    onClick={() => deleteConfig(row.id)}
                  >
                    <DeleteIcon sx={styles.deleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConfigsList;
