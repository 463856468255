// @flow
/* istanbul ignore file */

import React from 'react';
import { Link, styled, Typography, Grid } from '@mui/material';
import ModalRoot, { initialModalState } from 'components/Modal';
import Agreement from 'components/Agreement';
import { useIntl } from 'react-intl';
import useCdr from 'hooks/useCdr';
import Layout from 'components/Layout';
import Snack from 'components/Snack';
import {
  BREADCRUMB_KEY,
  getBreadcrumbsLinks,
  LAYOUT_BODY,
  modalNames,
} from 'constants/globalConsts';
// $FlowFixMe
import { useNavigate, useParams } from 'react-router';

const styles = {
  container: {
    alignItems: 'center',
  },
  videoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  player: {
    marginBottom: '16px',
    width: '100%',
  },
  checkbox: {
    padding: '0px 9px',
  },
  videoTitle: {
    fontSize: 21,
    lineHeight: '28px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  videoDescriptionLink: {
    fontSize: 16,
    cursor: 'pointer',
    lineHeight: '21px',
  },
  agreement: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
};

const Player = styled('video')(() => styles.player);

const Introduction = (): React$Element<any> => {
  const [cdr, setCdr] = useCdr();
  const [modalProps, setModalProps] = React.useState(initialModalState);
  const intl = useIntl();
  const params = useParams();
  const navigate = useNavigate();
  const breadCrumbsLinks = getBreadcrumbsLinks(
    intl,
    cdr ? cdr.id : '',
    BREADCRUMB_KEY.INTRODUCTION,
  );

  const handleChange = (isChecked: boolean) => {
    // $FlowFixMe
    setCdr({ ...cdr, gdprChecked: isChecked });
  };

  const handleOpenModal = () => {
    setModalProps({
      isOpen: true,
      name: modalNames.VIDEO_DESCRIPTION,
      handleClose: () => setModalProps(initialModalState),
    });
  };

  const onAgreeButtonPress = () => {
    if (!cdr?.gdprChecked) {
      return null;
    }
    const configId = params.id;
    // $FlowFixMe
    return navigate(
      `/config-discovery-request/${configId}/contact-information`,
    );
  };

  if (!cdr) {
    return (
      <Layout body={LAYOUT_BODY.CENTERED} breadCrumbsLinks={breadCrumbsLinks}>
        <div />
      </Layout>
    );
  }

  return (
    <Layout body={LAYOUT_BODY.CENTERED} breadCrumbsLinks={breadCrumbsLinks}>
      <ModalRoot {...modalProps} />

      <Snack />

      <Grid container align="center" sx={styles.container}>
        <Grid item xl={6} md={6} sm={12} xs={12} sx={styles.agreement}>
          <Agreement
            gdprChecked={cdr.gdprChecked}
            handleCheckboxClick={handleChange}
            buttonDisabled={!cdr.gdprChecked}
            onAgreeButtonPress={onAgreeButtonPress}
          />
        </Grid>

        <Grid item xl={6} md={6} sm={12} xs={12} sx={styles.videoContent}>
          <Player muted controls src="/Arrow-Discovery-Video.mp4" />

          <Typography variant="inherit" sx={styles.videoTitle}>
            {intl.formatMessage({
              id: 'routes.introduction.videoTitle',
              defaultMessage: 'Introduction Video',
            })}
          </Typography>

          <Typography>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              sx={styles.videoDescriptionLink}
              href={undefined}
              onClick={() => handleOpenModal()}
              underline="hover"
            >
              {intl.formatMessage({
                id: 'routes.introduction.videoDescription',
                defaultMessage: 'Video description',
              })}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Introduction;
