// @flow

import React from 'react';
import { Button, Checkbox, Link, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const styles = {
  agreementContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    alignContent: 'center',
    margin: 'auto 0',
    '& .Mui-disabled': {
      background: '#F0F0F0',
      borderRadius: '2px',
      borderColor: 'transparent',
    },
  },
  checkboxWrapper: {
    marginBottom: (theme: Object) => theme.spacing(6),
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 'bold',
    color: '#000000',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'max-content',
  },
  link: {
    cursor: 'pointer',
    marginLeft: '4px',
  },
  healthCheckText: {
    margin: 0,
  },
};

type Props = {|
  gdprChecked: boolean,
  handleCheckboxClick: Function,
  onAgreeButtonPress: Function,
|};

const AgreementContainer = styled('div')(() => styles.agreementContainer);
const CheckboxWrapper = styled('div')(() => {});
const FlexRow = styled('div')(() => styles.flexRow);

const Agreement = (props: Props): React$Element<any> => {
  const intl = useIntl();

  const { gdprChecked, handleCheckboxClick, onAgreeButtonPress } = props;

  return (
    <AgreementContainer>
      <Typography paragraph variant="inherit" role="article" sx={styles.title}>
        {intl.formatMessage({
          id: 'components.agreement.mainTitle',
          defaultMessage: 'Welcome to your Xpand Config Discovery Request',
        })}
      </Typography>
      <Typography paragraph />
      <Typography variant="inherit" role="article" paragraph>
        {intl.formatMessage({
          id: 'components.agreement.mainDescription',
          defaultMessage:
            'The purpose of this tool is to allow you to analyze your Citrix ADC Configurations so a quote can be made for :',
        })}
      </Typography>
      <div>
        <Typography
          variant="inherit"
          role="article"
          paragraph
          sx={styles.healthCheckText}
        >
          {intl.formatMessage({
            id: 'components.agreement.audit',
            defaultMessage: '• Citrix ADC HealthCheck Services',
          })}
        </Typography>
        <Typography paragraph variant="inherit" role="article">
          {intl.formatMessage({
            id: 'components.agreement.managed',
            defaultMessage: '• Citrix ADC Managed Services',
          })}
        </Typography>
      </div>
      <Typography variant="inherit" role="article" paragraph>
        {intl.formatMessage({
          id: 'components.agreement.process',
          defaultMessage:
            'During the process you can follow every step on how your configuration data is processed.',
        })}
      </Typography>
      <Typography variant="inherit" role="article" paragraph>
        {intl.formatMessage({
          id: 'components.agreement.consent',
          defaultMessage: 'By continuing to the next screen you agree with :',
        })}
      </Typography>
      <CheckboxWrapper sx={styles.checkboxWrapper}>
        <FlexRow
          tabIndex="0"
          role="row"
          onClick={() => handleCheckboxClick(!gdprChecked)}
          onKeyPress={() => handleCheckboxClick(!gdprChecked)}
        >
          <Checkbox
            checked={gdprChecked}
            name="gdprChecked"
            onChange={(e) => handleCheckboxClick(e.target.checked)}
            color="secondary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography variant="inherit" role="article">
            {intl.formatMessage({
              id: 'components.agreement.the',
              defaultMessage: 'The',
            })}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={(event) => event.stopPropagation()}
              target="_blank"
              role="link"
              sx={styles.link}
              href={null}
              underline="hover"
            >
              {intl.formatMessage({
                id: 'components.agreement.gdpr',
                defaultMessage: 'Confidentiality & Privacy Policy',
              })}
            </Link>
          </Typography>
        </FlexRow>
      </CheckboxWrapper>
      <Button
        variant="contained"
        color="primary"
        onClick={onAgreeButtonPress}
        disabled={!gdprChecked}
      >
        {intl.formatMessage({
          id: 'components.agreement.actionAgree',
          defaultMessage: 'I Agree',
        })}
      </Button>
    </AgreementContainer>
  );
};

export default Agreement;
