// @flow
import React from 'react';
import type { Node } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type FormFieldCheckboxProps = {
  field: Object,
  onChange: Function,
  value: string,
  error: string,
  styles?: Object,
  disabled?: boolean,
};

const FormFieldCheckbox = ({
  styles,
  field,
  onChange,
  value,
  error,
  disabled,
}: FormFieldCheckboxProps): Node => (
  <FormControlLabel
    control={
      <Checkbox
        {...field}
        checked={Boolean(value)}
        onChange={onChange}
        error={error && Boolean(error)}
      />
    }
    label={
      <Typography variant="inherit" sx={styles && styles.checkboxLabel}>
        {field.label}
      </Typography>
    }
    disabled={disabled}
    sx={styles && styles.checkboxControl}
  />
);

FormFieldCheckbox.defaultProps = {
  styles: {},
  disabled: false,
};

export default FormFieldCheckbox;
