// @flow
import React from 'react';
import { Button } from '@mui/material';

const styles = {
  linkRoot: {
    height: 'auto',
    color: '#0069AA',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0,
    padding: 0,
    fontFamily: 'Arrow Text',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '14px',
    '&&:hover': {
      background: 'transparent',
    },
  },
};

type TypeButtonLink = {
  text: string,
  callback?: Function,
  href: ?string,
};

const ButtonLink = ({
  text,
  callback,
  href,
}: TypeButtonLink): React$Element<any> => (
  <Button
    component="a"
    href={href}
    role="link"
    onClick={callback}
    sx={styles.linkRoot}
  >
    {text}
  </Button>
);

ButtonLink.defaultProps = {
  callback: () => {},
};

export default ButtonLink;
