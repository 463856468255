// @flow

import React from 'react';
import { styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {
  breadCrumbsConfigStatus,
  breadCrumbSeparatorStatus,
} from 'constants/globalConsts';
import Separator from '../Separator';

const styles = {
  breadCrumbsContainer: { display: 'flex', margin: '28px 0 0 90px' },
  breadCrumbActiveCircle: {
    width: '24px',
    height: '24px',
    border: '2px solid #0084D5',
    borderRadius: '50%',
  },
  breadCrumbInactiveCircle: {
    width: '24px',
    height: '24px',
    border: '2px solid #ffffff',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  breadCrumbDoneCircle: {
    width: '24px',
    height: '24px',
    border: '2px solid #0084D5',
    borderRadius: '50%',
    backgroundColor: '#0084D5',
  },
  breadCrumbActiveNumber: {
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: 400,
    margin: '0 0 0 6px',
    color: '#0084D5',
  },
  breadCrumbInactiveNumber: {
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: 400,
    margin: '0 0 0 6px',
    color: '#666666',
  },
  labelActive: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#000000',
    margin: '0 0 0 16px',
  },
  labelInactive: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#666666',
    margin: '0 0 0 16px',
  },
  activeSeparator: {
    width: '95px',
    height: '5px',
    borderRadius: '5px',
    background: '#0069AA',
    margin: '10px 16px 0 16px',
  },
  inactiveSeparator: {
    width: '95px',
    height: '5px',
    background: '#FFFFFF',
    borderRadius: '5px',
    margin: '10px 16px 0 16px',
  },
  checkIcon: {
    color: '#ffffff',
  },
};

type GatherDataBreadcrumbsProps = {
  config: Array<Object>,
  separatorStatus: string,
};

const Wrapper = styled('div')(() => {});
const Number = styled('span')(() => {});
const Label = styled('div')(() => {});
const BreadCrumbsContainer = styled('div')(() => styles.breadCrumbsContainer);

const GatherDataBreadcrumbs = ({
  config,
  separatorStatus,
}: GatherDataBreadcrumbsProps): React$Element<any> => {
  const getBreadCrumbNumber = (number: number, status: string) => {
    let circleClassName = 'breadCrumbActiveCircle';
    let numberClassName = 'breadCrumbActiveNumber';

    if (status === breadCrumbsConfigStatus.inactiveStatus) {
      circleClassName = 'breadCrumbInactiveCircle';
      numberClassName = 'breadCrumbInactiveNumber';
    } else if (status === breadCrumbsConfigStatus.doneStatus) {
      circleClassName = 'breadCrumbDoneCircle';
    }

    return (
      <Wrapper sx={styles[circleClassName]}>
        {status === breadCrumbsConfigStatus.doneStatus ? (
          <CheckIcon fontSize="small" sx={styles.checkIcon} />
        ) : (
          <Number sx={styles[numberClassName]}>{number}</Number>
        )}
      </Wrapper>
    );
  };

  const getBreadcrumbSeparator = (configIndex: number) => {
    const separatorClassName =
      separatorStatus === breadCrumbSeparatorStatus.activeStatus
        ? 'activeSeparator'
        : 'inactiveSeparator';

    return configIndex < config.length - 1 ? (
      <Separator
        key="breadceumbSeparator"
        styles={styles[separatorClassName]}
      />
    ) : null;
  };

  const getBreadcrumbSummary = (crumb: Object) => {
    let labelClassName = 'labelInactive';

    if (
      crumb.status === breadCrumbsConfigStatus.activeStatus ||
      crumb.status === breadCrumbsConfigStatus.doneStatus
    ) {
      labelClassName = 'labelActive';
    }

    return (
      <React.Fragment key="breadcrumbSummary">
        <div>{getBreadCrumbNumber(crumb.number, crumb.status)}</div>
        <Label sx={styles[labelClassName]}>{crumb.label}</Label>
      </React.Fragment>
    );
  };

  return (
    <BreadCrumbsContainer>
      {config.map((crumb, index) => [
        getBreadcrumbSummary(crumb),
        getBreadcrumbSeparator(index),
      ])}
    </BreadCrumbsContainer>
  );
};

export default GatherDataBreadcrumbs;
