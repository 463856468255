// @flow
/* istanbul ignore file */
import React from 'react';
import type { Node } from 'react';
import { styled } from '@mui/material';
import { Header, Footer } from 'xpand-components';
import { footerProps, headerProps, LAYOUT_BODY } from 'constants/globalConsts';

type TypeBody = 'default' | 'full' | 'centered';

type TypeLinks = {
  title: string,
  link: string,
  active: boolean,
};

type Props = {|
  children: Node,
  body?: TypeBody,
  breadCrumbsLinks?: Array<TypeLinks>,
|};

const styles = {
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column wrap',
    flexGrow: 1,
    minHeight: '100vh',
    maxWidth: '100%',
  },
  bodyContainer: {
    flexGrow: 1,
  },
  bodyDefault: {
    padding: (theme: Object) => `${theme.spacing(10)} ${theme.spacing(15)}`,
  },
  bodyFull: {
    padding: (theme: Object) => `0 0 ${theme.spacing(10)} 0`,
  },
  bodyCentered: {
    padding: (theme: Object) => `${theme.spacing(10)} ${theme.spacing(20)}`,
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
  },
};

const BodyContainer = styled('div')(() => {});
const MainContainer = styled('div')(() => styles.mainContainer);

const Layout = (props: Props): React$Element<any> => {
  const { children, body, breadCrumbsLinks } = props;

  let bodyStyle = styles.bodyDefault;

  if (body === LAYOUT_BODY.CENTERED) {
    bodyStyle = styles.bodyCentered;
  } else if (body === LAYOUT_BODY.FULL) {
    bodyStyle = styles.bodyFull;
  }

  return (
    <MainContainer>
      <Header {...headerProps} breadcrumbsLinks={breadCrumbsLinks} />

      <BodyContainer sx={{ ...styles.bodyContainer, ...bodyStyle }}>
        {children}
      </BodyContainer>

      <Footer {...footerProps} />
    </MainContainer>
  );
};

Layout.defaultProps = {
  body: LAYOUT_BODY.DEFAULT,
  // $FlowFixMe
  breadCrumbsLinks: [],
};

export default Layout;
