// @flow
/* istanbul ignore file */

import React from 'react';
// $FlowFixMe
import { Route, Routes, useNavigate } from 'react-router-dom';
import useCdr from 'hooks/useCdr';
import { CircularProgress, styled } from '@mui/material';
import Configs from './configs';
import Introduction from './introduction';
import ContactInformation from './contactInformation';
import NotFound from './notFound';
import Review from './review';

const styles = {
  loaderContainer: {
    width: '100%',
    height: 'calc(100vh - 330px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const LoaderContainer = styled('div')(() => styles.loaderContainer);

const AppRoutes = (): React$Element<any> => {
  const navigate = useNavigate();
  const [cdr, , isLoading] = useCdr();

  if (isLoading) {
    return (
      <LoaderContainer>
        <CircularProgress size={64} color="secondary" />
      </LoaderContainer>
    );
  }

  if (!isLoading && !cdr) {
    navigate('/');
  }

  return (
    <Routes>
      <Route path="/config-discovery-request/:id" element={<Introduction />} />
      <Route path="/config-discovery-request/:id/review" element={<Review />} />
      <Route
        path="/config-discovery-request/:id/contact-information"
        element={<ContactInformation />}
      />
      <Route
        path="/config-discovery-request/:id/configs"
        element={<Configs />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
