// @flow
/* istanbul ignore file */
import { styled } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
// $FlowFixMe
import { useNavigate, useParams } from 'react-router-dom';
import useCdr from 'hooks/useCdr';
import { buildCdrData } from 'utils/contactInformationUtils';
import {
  breadCrumbSeparatorStatus,
  BREADCRUMB_KEY,
  getBreadcrumbsLinks,
  LAYOUT_BODY,
} from 'constants/globalConsts';
import { getBreadCrumbsConfig } from 'constants/contactInformationConsts';
import Layout from 'components/Layout';
import GatherDataBreadcrumbs from '../../components/GatherDataBreadcrumbs';
import ContactInformationForm from '../../components/ContactInformationForm';

const styles = {
  breadcrumbsContainer: {
    width: '100%',
    height: '80px',
    backgroundColor: '#CCCCCC',
    display: 'flex',
  },
  contactInformationForm: {
    margin: '80px 120px 0 120px',
  },
};

const BreadcrumbsContainer = styled('div')(() => styles.breadcrumbsContainer);
const ContactInformationFormWrapper = styled('div')(
  () => styles.contactInformationForm,
);

const ContactInformation = (): React$Element<any> => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [cdr, setCdr] = useCdr();
  const breadCrumbsLinks = getBreadcrumbsLinks(
    intl,
    cdr ? cdr.id : '',
    BREADCRUMB_KEY.GATHER_DATA,
  );

  React.useEffect(() => {
    if (cdr && Object.keys(cdr).length && !cdr.gdprChecked) {
      // $FlowFixMe
      navigate(`/config-discovery-request/${params.id}`);
    }
  }, [cdr, navigate, params]);

  const handleFormSubmit = (formData: Object) => {
    const builtCdrData = buildCdrData(formData);

    setCdr({
      ...cdr,
      ...builtCdrData,
    });
    // $FlowFixMe
    navigate(`/config-discovery-request/${params.id}/configs`);
  };

  const onPrevButtonClick = () =>
    // $FlowFixMe
    navigate(`/config-discovery-request/${params.id}`);

  return (
    <Layout body={LAYOUT_BODY.FULL} breadCrumbsLinks={breadCrumbsLinks}>
      <BreadcrumbsContainer>
        <GatherDataBreadcrumbs
          config={getBreadCrumbsConfig(intl)}
          separatorStatus={breadCrumbSeparatorStatus.inactiveStatus}
        />
      </BreadcrumbsContainer>
      <ContactInformationFormWrapper>
        <ContactInformationForm
          handleFormSubmit={handleFormSubmit}
          cdrData={cdr}
          onPrevButtonClick={onPrevButtonClick}
        />
      </ContactInformationFormWrapper>
    </Layout>
  );
};

export default ContactInformation;
