// @flow
/* istanbul ignore file */
import {
  styled,
  Button,
  Snackbar,
  IconButton,
  CircularProgress,
} from '@mui/material';
// $FlowFixMe
import ClearIcon from '@mui/icons-material/Clear';
// $FlowFixMe
import { Alert } from '@mui/lab';
import ConfigsList from 'components/ConfigsList';
// $FlowFixMe
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useParser, DropFile } from 'xpand-components';
import GatherDataBreadcrumbs from 'components/GatherDataBreadcrumbs';
import {
  breadCrumbSeparatorStatus,
  LF_FILE_PREFIX_KEY,
  FILE_STATUS,
  LAYOUT_BODY,
  getBreadcrumbsLinks,
  BREADCRUMB_KEY,
} from 'constants/globalConsts';
import {
  ANALYSIS_ITEMS_KEY,
  getBreadCrumbsConfig,
} from 'constants/configsListConsts';
import { useIntl } from 'react-intl';
import Separator from 'components/Separator';
import useCdr from 'hooks/useCdr';
import localforage from 'localforage';
import Layout from 'components/Layout';
// $FlowFixMe
import { v4 as uuidv4 } from 'uuid';

type ParsedFile = {
  analysisItems: Array<Object>,
  configParsed: Object,
  fileName: string,
  stats: Object,
};

type UploadedFile = {
  fileName: string,
  id: string,
  status: string,
  adcFormFactor?: string,
  adcLicense?: string,
  analysis?: Object,
  instanceName?: string,
  invalid?: boolean,
};

type UploadedFiles = Array<UploadedFile>;

const styles = {
  breadcrumbsContainer: {
    width: '100%',
    height: '80px',
    backgroundColor: '#CCCCCC',
    display: 'flex',
  },
  bodyContainer: {
    margin: '80px 120px 0 120px',
  },
  header: {
    display: 'flex',
  },
  headerTitle: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    margin: '0 0 24px 0',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  headerSecondTitle: {
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#CCCCCC',
    margin: '0 0 4px 16px',
  },
  previousButtonLink: {
    textDecoration: 'none',
    color: '#505050',
  },
  actionButton: {
    margin: '24px 16px 0 0',
  },
  dropFileContainer: {
    margin: '32px 0 0 0',
  },
  snackBarAlertMessage: {
    lineHeight: '20px',
  },
  circularProgressWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CircularProgressWrapper = styled('div')(
  () => styles.circularProgressWrapper,
);

const BreadcrumbsContainer = styled('div')(() => styles.breadcrumbsContainer);
const BodyContainer = styled('div')(() => styles.bodyContainer);
const Header = styled('div')(() => styles.header);
const HeaderTitle = styled('div')(() => styles.headerTitle);
const HeaderSecondTitle = styled('div')(() => styles.headerSecondTitle);
const DropFileContainer = styled('div')(() => styles.dropFileContainer);

const cleanupAnalysis = (payload: ParsedFile) =>
  Object.keys(payload).reduce((accumulator, key: string) => {
    switch (key) {
      case ANALYSIS_ITEMS_KEY:
        // eslint-disable-next-line no-case-declarations
        const analysisItems = payload.analysisItems.filter(
          (item) => item.matched === true,
        );

        analysisItems.map((item) => {
          // eslint-disable-next-line no-param-reassign
          delete item.objects;
          return item;
        });

        return {
          ...accumulator,
          analysisItems,
        };
      default:
        return {
          ...accumulator,
          [key]: payload[key],
        };
    }
  }, {});

const Configs = (): React$Element<any> => {
  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();
  const [cdr, setCdr, isLoading] = useCdr();
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFiles>([]);
  const [snack, setSnack] = React.useState<?string>(null);
  const breadCrumbsLinks = getBreadcrumbsLinks(
    intl,
    cdr ? cdr.id : '',
    BREADCRUMB_KEY.GATHER_DATA,
  );

  React.useEffect(() => {
    const checkForFiles = async () => {
      const keys = await localforage.keys();
      const result = [];
      if (keys && keys.length > 0) {
        await Promise.all(
          keys.map(async (key) => {
            if (key.indexOf(`${LF_FILE_PREFIX_KEY}/`) !== -1) {
              const file = await localforage.getItem(key);
              const id = key.split('/')[1];
              result.push({
                id,
                fileName: file.name,
                status: FILE_STATUS.UPLOADED,
              });
            }
          }),
        );
      }
      if (result.length > 0) {
        setUploadedFiles(result);
      }
    };
    checkForFiles();
  }, []);

  React.useEffect(() => {
    if (cdr && Object.keys(cdr).length && !cdr.gdprChecked) {
      // $FlowFixMe
      navigate(`/config-discovery-request/${params.id}`);
    } else if (!cdr && !isLoading) {
      navigate(`/not-found`);
    }
  }, [cdr, navigate, params, isLoading]);

  const isUploadedFileInvalid = (fileData: ParsedFile) => {
    const { stats, configParsed } = fileData;
    let invalidProps = { isInvalid: false, invalidMessage: '' };

    if (stats.ha) {
      const isHaDuplicate = uploadedFiles.some(
        (file) =>
          file.analysis &&
          file.analysis.stats &&
          file.analysis.stats.peernodeip === stats.nodeip,
      );

      if (isHaDuplicate) {
        invalidProps = {
          isInvalid: isHaDuplicate,
          invalidMessage: intl.formatMessage({
            id: 'configs.error.hapair',
            defaultMessage: 'Detected as HA duplicate',
          }),
        };
      }
    } else if (configParsed.errors) {
      invalidProps = {
        isInvalid: true,
        invalidMessage: intl.formatMessage({
          id: 'configs.error.invalidFile',
          defaultMessage: 'Errors parsing file',
        }),
      };
    }

    return invalidProps;
  };

  const onSuccess = (payload: ParsedFile) => {
    const analysis = cleanupAnalysis({ ...payload });

    setUploadedFiles(
      uploadedFiles.map((el) => {
        let result = el;
        const { isInvalid, invalidMessage } = isUploadedFileInvalid(payload);

        // parser fileName here is actually the id
        if (el.id === payload.fileName) {
          const cdrFileData =
            cdr && cdr.files.find((file) => file.id === el.id);

          result = {
            ...el,
            status: isInvalid ? FILE_STATUS.INVALID : FILE_STATUS.FINISHED,
            analysis,
            instanceName: payload.stats.hostname || '',
          };

          if (cdrFileData) {
            result = {
              ...result,
              instanceName: cdrFileData.instanceName,
              adcFormFactor: cdrFileData.adcFormFactor,
              adcLicense: cdrFileData.adcLicense,
            };
          }

          if (isInvalid) {
            result = {
              ...result,
              invalid: isInvalid,
              invalidMessage,
            };
          }
        }

        return result;
      }),
    );
  };

  const onError = (err: Object) => {
    console.error(err);
  };

  const [parse] = useParser(onError, onSuccess);

  React.useEffect(() => {
    let updatedFiles = 0;
    const newData = uploadedFiles.map((fileData) => {
      if (fileData.status === 'uploaded') {
        updatedFiles += 1;
        return { ...fileData, status: FILE_STATUS.ANALYSIS };
      }
      return { ...fileData };
    });
    if (updatedFiles > 0) {
      setTimeout(() => setUploadedFiles(newData), 500);
      return;
    }

    uploadedFiles.forEach(async (fileData) => {
      if (fileData.status !== FILE_STATUS.ANALYSIS) {
        return;
      }

      const file = await localforage.getItem(
        `${LF_FILE_PREFIX_KEY}/${fileData.id}`,
      );
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        parse(fileData.id, reader.result);
      };

      reader.onerror = () => {
        console.error(reader.error);
      };
    });
  }, [uploadedFiles, setUploadedFiles, parse]);

  const onDrop = async (files) => {
    const filesHelper = [];
    await Promise.all(
      files.map(async (file) => {
        try {
          const id = uuidv4();
          await localforage.setItem(`${LF_FILE_PREFIX_KEY}/${id}`, file);

          filesHelper.push({
            fileName: file.name,
            id,
            status: FILE_STATUS.UPLOADED,
          });
        } catch (err) {
          console.error(err);
        }
      }),
    );
    setUploadedFiles([...uploadedFiles, ...filesHelper]);
  };

  const onDropRejected = (rejectedFiles) => {
    const fileNames = rejectedFiles.map((data) => data.file.name).join(', ');
    const invalidFormatPrefix = intl.formatMessage({
      id: 'configs.invalidFileFormat.prefix',
      defaultMessage: 'The following file(s):',
    });
    const invalidFormatSuffix = intl.formatMessage({
      id: 'configs.invalidFileFormat.suffix',
      defaultMessage: 'must be of type .txt or .conf',
    });

    setSnack(`${invalidFormatPrefix} ${fileNames} ${invalidFormatSuffix}`);
  };

  const deleteConfig = (configFileId) => {
    localforage.removeItem(`${LF_FILE_PREFIX_KEY}/${configFileId}`);

    setCdr({
      ...cdr,
      files:
        cdr &&
        cdr.files.filter((uploadedFile) => uploadedFile.id !== configFileId),
    });

    setUploadedFiles(
      uploadedFiles.filter((uploadedFile) => uploadedFile.id !== configFileId),
    );
  };

  const changeConfigData = (configFileId, key, value) => {
    setUploadedFiles(
      uploadedFiles.map((el) =>
        el.id === configFileId
          ? {
              ...el,
              [key]: value,
            }
          : el,
      ),
    );
  };

  const handleNext = () => {
    const hasError = uploadedFiles.some(
      (file) =>
        !file.invalid &&
        (!('adcFormFactor' in file) ||
          !('adcLicense' in file) ||
          !('instanceName' in file) ||
          !file.adcFormFactor ||
          !file.adcLicense ||
          !file.instanceName),
    );

    if (hasError) {
      setSnack(
        intl.formatMessage({
          id: 'configs.required.fields',
          defaultMessage: 'Please fill all fields',
        }),
      );
      return;
    }

    const files = uploadedFiles.filter((file) => !file.invalid);

    setCdr({
      ...cdr,
      files,
    });
    // $FlowFixMe
    navigate(`/config-discovery-request/${params.id}/review`);
  };

  const handleSnackClose = (event, reason) => {
    if (reason && reason === 'clickaway') {
      return;
    }

    setSnack(null);
  };

  const onPrevButtonClick = () =>
    // $FlowFixMe
    navigate(`/config-discovery-request/${params.id}/contact-information`);

  if (isLoading && !cdr) {
    return (
      <Layout body={LAYOUT_BODY.FULL} breadCrumbsLinks={breadCrumbsLinks}>
        <CircularProgressWrapper>
          <CircularProgress color="secondary" />
        </CircularProgressWrapper>
      </Layout>
    );
  }

  return (
    <Layout body={LAYOUT_BODY.FULL} breadCrumbsLinks={breadCrumbsLinks}>
      <BreadcrumbsContainer>
        <GatherDataBreadcrumbs
          config={getBreadCrumbsConfig(intl)}
          separatorStatus={breadCrumbSeparatorStatus.activeStatus}
        />
      </BreadcrumbsContainer>
      <BodyContainer>
        <Header>
          <HeaderTitle>
            {intl.formatMessage({
              id: 'configs.headerTitle',
              defaultMessage: 'Citrix ADC Configs',
            })}
            <HeaderSecondTitle>
              {(cdr && cdr.contactInfo && cdr.contactInfo.companyName) || ''}
            </HeaderSecondTitle>
          </HeaderTitle>
        </Header>
        <Separator />
        <ConfigsList
          data={uploadedFiles}
          deleteConfig={deleteConfig}
          changeConfigData={changeConfigData}
        />
        <DropFileContainer>
          <DropFile onDrop={onDrop} onDropRejected={onDropRejected} />
        </DropFileContainer>
        <div>
          <Button
            onClick={onPrevButtonClick}
            sx={styles.actionButton}
            variant="outlined"
            color="primary"
          >
            {intl.formatMessage({
              id: 'configs.previousButton',
              defaultMessage: 'Previous',
            })}
          </Button>
          <Button
            key="nextButton"
            sx={styles.actionButton}
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={
              uploadedFiles.length === 0 ||
              uploadedFiles.some(
                (file) =>
                  file.status === FILE_STATUS.ANALYSIS ||
                  file.status === FILE_STATUS.UPLOADED,
              )
            }
          >
            {intl.formatMessage({
              id: 'configs.nextButton',
              defaultMessage: 'Next',
            })}
          </Button>
        </div>
      </BodyContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack !== null}
        autoHideDuration={5000}
        onClose={handleSnackClose}
        message={snack}
      >
        <Alert
          severity="error"
          sx={styles.snackBarAlertMessage}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => handleSnackClose(e, 'iconClick')}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {snack}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Configs;
