// @flow

import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
// $FlowFixMe
import { useIntl } from 'react-intl';
import Separator from 'components/Separator';
import type { ChildProps } from './ModalRoot';

const styles = {
  buttonRow: {
    justifyContent: 'center',
  },
  separator: {
    width: 'calc(100% - 96px)',
    margin: (theme: Object) => `0px ${theme.spacing(7)}`,
  },
};

const VideoDescription = ({ handleClose }: ChildProps): React$Element<any> => {
  const intl = useIntl();

  return (
    <>
      <DialogTitle
        data-testid="VideoDescription-modal-title"
        id="alert-dialog-title"
      >
        {intl.formatMessage({
          id: 'modal.videoDescription.title',
          defaultMessage: 'Video title',
        })}
      </DialogTitle>
      <Separator styles={styles.separator} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({
            id: 'modal.videoDescription.description',
            defaultMessage: 'Video description',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.buttonRow}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
        >
          {intl.formatMessage({
            id: 'modal.videoDescription.actionAgree',
            defaultMessage: 'Ok',
          })}
        </Button>
      </DialogActions>
    </>
  );
};

export default VideoDescription;
