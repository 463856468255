// @flow
/* istanbul ignore file */

import React, { useEffect } from 'react';
import type { Node } from 'react';
import ReviewCompleted from 'components/ReviewCompleted';
import ReviewProgress from 'components/ReviewProgress';
import ReviewRequest from 'components/ReviewRequest';
// $FlowFixMe
import axios from 'axios';
import {
  BREADCRUMB_KEY,
  getBreadcrumbsLinks,
  LF_FILE_PREFIX_KEY,
  modalNames,
} from 'constants/globalConsts';

import ModalRoot, { initialModalState } from 'components/Modal';
import useCdr from 'hooks/useCdr';
import Layout from 'components/Layout';

import localforage from 'localforage';
import { useIntl } from 'react-intl';
// $FlowFixMe
import { useNavigate, useParams } from 'react-router';
import { stripConfigSenesitiveInfo } from 'utils/configUtils';

const Review = (): Node => {
  const [modalProps, setModalProps] = React.useState(initialModalState);

  const [currentConfigCount, setCurrentConfigCount] = React.useState(0);
  const [countAnalytics, setCountAnalytics] = React.useState(0);
  const [reviewProgressCount, setReviewProgressCount] = React.useState(0);
  const [s3Checked, setS3Checked] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [cdr, setCdr] = useCdr();
  const intl = useIntl();
  const breadCrumbsLinks = getBreadcrumbsLinks(
    intl,
    cdr ? cdr.id : '',
    BREADCRUMB_KEY.REVIEW,
  );
  useEffect(() => {
    if (cdr && Object.keys(cdr).length && !cdr.gdprChecked) {
      // $FlowFixMe
      navigate(`/config-discovery-request/${params.id}`);
    } else if (cdr && cdr.files.length === 0) {
      // $FlowFixMe
      navigate(`/config-discovery-request/${params.id}/configs`);
    }
  });

  const fileUpload = async (file: Object) =>
    new Promise((resolve, reject) => {
      localforage
        .getItem(`${LF_FILE_PREFIX_KEY}/${file.id}`)
        .then((uploadedFile) => {
          const strippedFile = stripConfigSenesitiveInfo(
            uploadedFile.name,
            file.analysis.configParsed,
          );
          const options = {
            headers: {
              'Content-Type':
                strippedFile.type.length > 0 ? strippedFile.type : 'text/plain',
              'x-amz-acl': 'public-read',
            },
          };

          axios
            // $FlowFixMe
            .put(file.url, strippedFile, options)
            .then(() => {
              setCurrentConfigCount((state) => state + 1);
              resolve();
            })
            .catch((err) => {
              console.error(err);
              reject();
            });
        })
        .catch(() => reject());
    });

  const sendAnalitycs = (cdrData: Object) => {
    const files = cdrData.files.map((file) => {
      const { configParsed, ...otherAnalysisProps } = file.analysis;

      return {
        ...file,
        analysis: otherAnalysisProps,
      };
    });

    return (
      axios
        // $FlowFixMe
        .post(`config-discovery-request/${cdrData.id}`, {
          contactInfo: cdrData.contactInfo,
          partner: cdrData.partner,
          files,
        })
        .then(() => {
          setCountAnalytics(1);
          setTimeout(() => {
            setReviewProgressCount(2);
            setCdr(null);
          }, 800);
        })
        .catch((err) => {
          console.error(err);
        })
    );
  };

  const handleProgressSubmit = async () => {
    if (!cdr) {
      return;
    }

    // $FlowFixMe
    const s3Array = await axios.get(
      `s3-upload-urls/${cdr.id}/uploads/${cdr.files.length}`,
    );
    const newFiles = [];
    // $FlowFixMe
    for (let i = 0; i < s3Array.data.length; i += 1) {
      newFiles.push({ ...cdr.files[i], ...s3Array.data[i] });
    }

    Promise.all(newFiles.map((el) => fileUpload(el)))
      .then(() => {
        sendAnalitycs({
          ...cdr,
          files: newFiles,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    if (reviewProgressCount === 1) {
      if (s3Checked) {
        handleProgressSubmit();
      } else {
        sendAnalitycs({ ...cdr });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewProgressCount]);

  const handleReviewSubmit = () => {
    if (!s3Checked) {
      setModalProps({
        isOpen: true,
        name: modalNames.REVIEW_WARNING,
        handleClose: () => setModalProps(initialModalState),
        callback: () => {
          setReviewProgressCount(1);
          setModalProps(initialModalState);
        },
      });
    } else {
      setReviewProgressCount(1);
    }
  };

  const renderBody = () => {
    switch (reviewProgressCount) {
      case 0:
        if (!cdr) {
          return null;
        }
        return (
          <ReviewRequest
            s3Checked={s3Checked}
            setS3Checked={() => setS3Checked(!s3Checked)}
            onSubmit={handleReviewSubmit}
            cdr={cdr}
          />
        );

      case 1:
        if (!cdr) {
          return null;
        }
        return (
          <ReviewProgress
            currentConfigCount={currentConfigCount}
            countConfig={s3Checked ? cdr.files.length : null}
            countAnalytics={countAnalytics}
          />
        );
      case 2:
        return <ReviewCompleted />;
      default:
        return null;
    }
  };

  return (
    <Layout breadCrumbsLinks={breadCrumbsLinks}>
      <ModalRoot {...modalProps} />
      {renderBody()}
    </Layout>
  );
};

export default Review;
