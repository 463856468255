// @flow

import React from 'react';
import { styled, Button } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import {
  addDataToConfig,
  parseDataToConfig,
} from 'utils/contactInformationUtils';
import { useIntl } from 'react-intl';
import {
  formFieldTypes,
  getFormFieldsConfig,
} from '../../constants/contactInformationConsts';
import FormFieldText from '../FormFieldText';
import FormFieldCheckbox from '../FormFieldCheckbox';
import FormFieldSelect from '../FormFieldSelect';
import Separator from '../Separator';

const styles = {
  title: {
    fontWeight: 700,
    fontSize: '24px',
    color: '#000000',
    margin: '0 0 24px 0',
    lineHeight: '32px',
  },
  previousButtonLink: {
    textDecoration: 'none',
    color: '#505050',
  },
  actionButton: {
    margin: '24px 16px 0 0',
  },
  formContainer: {
    margin: '32px 0 0 0',
  },
  checkboxControl: {
    margin: '25px 0 0 0',
  },
  checkboxLabel: {
    fontSize: '11px',
    lineHeight: '14px',
    fontWeight: 400,
    color: '#000000',
  },
  selectPlaceholder: {
    lineHeight: '24px',
    fontWeight: 400,
    color: '#939393',
    fontSize: '13px',
  },
  selectForm: {
    margin: '0 24px 0 0',
    width: '240px',
  },
};

type Props = {
  cdrData: Object,
  handleFormSubmit: Function,
  onPrevButtonClick: Function,
};

const Title = styled('div')(() => styles.title);
const FormContainer = styled('div')(() => styles.formContainer);

const ContactInformationForm = ({
  cdrData,
  handleFormSubmit,
  onPrevButtonClick,
}: Props): React$Element<any> => {
  const intl = useIntl();
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState, reset } = methods;
  const usePrevious = (value: Object) => {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevPropValues = usePrevious({ cdrData });

  React.useEffect(() => {
    if (prevPropValues && prevPropValues.cdrData !== cdrData) {
      /* istanbul ignore next */
      reset(parseDataToConfig(cdrData));
    }
  });

  const renderActionButtons = () => [
    <Button
      onClick={onPrevButtonClick}
      sx={styles.actionButton}
      variant="outlined"
      color="primary"
      key="contactFormPreviousButton"
    >
      {intl.formatMessage({
        id: 'contactInfoPage.previousButton',
        defaultMessage: 'Previous',
      })}
    </Button>,
    <Button
      key="nextButton"
      sx={styles.actionButton}
      variant="contained"
      color="primary"
      type="submit"
      disabled={!formState.isValid}
    >
      {intl.formatMessage({
        id: 'contactInfoPage.nextButton',
        defaultMessage: 'Next',
      })}
    </Button>,
  ];

  const validateField = (field: Object, controllerProps: Object) => {
    const { type, validator } = field;
    const { value, error } = controllerProps;
    let validatedError = error;

    if (
      type === formFieldTypes.inputFieldType ||
      type === formFieldTypes.selectFieldType
    ) {
      validatedError = validator ? validator(value) : error;
    }

    return validatedError;
  };

  const getFieldControl = (configField: Object, controllerProps: Object) => {
    const { type } = configField;
    const { onChange, value } = controllerProps;
    let control = null;

    if (type === formFieldTypes.inputFieldType) {
      control = (
        <FormFieldText
          field={configField}
          onChange={onChange}
          value={value}
          error={validateField(configField, controllerProps)}
          styles={styles}
        />
      );
    } else if (type === formFieldTypes.checkboxFieldType) {
      control = (
        <FormFieldCheckbox
          field={configField}
          onChange={onChange}
          value={value}
          error={validateField(configField, controllerProps)}
          styles={styles}
        />
      );
    } else if (type === formFieldTypes.selectFieldType) {
      control = (
        <FormFieldSelect
          field={configField}
          onChange={onChange}
          value={value}
          error={validateField(configField, controllerProps)}
          styles={styles}
        />
      );
    }

    return control;
  };

  const getControllerProps = (field: Object) => {
    const { key, errorMessage, type } = field;
    let controllerProps = {
      key,
      name: key,
      rules: { required: errorMessage },
    };

    if (type === formFieldTypes.inputFieldType) {
      controllerProps = {
        ...controllerProps,
        defaultValue: field.value || '',
      };
    } else if (type === formFieldTypes.checkboxFieldType) {
      controllerProps = {
        ...controllerProps,
        defaultValue: field.value || false,
      };
    } else if (type === formFieldTypes.selectFieldType) {
      controllerProps = {
        ...controllerProps,
        defaultValue: field.value || '',
      };
    }

    return controllerProps;
  };

  const renderFormFields = () => {
    const config = addDataToConfig(cdrData, getFormFieldsConfig(intl));
    const fieldSections = [];

    config.forEach((configRowFields, index) => {
      const fieldControls = configRowFields.map((confField) => (
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) =>
            getFieldControl(confField, { onChange, value, error })
          }
          {...getControllerProps(confField)}
        />
      ));

      fieldSections.push(
        <div key={`fieldsSection-${config.length + index}`}>
          {fieldControls}
        </div>,
      );
    });

    return fieldSections;
  };

  return (
    <div>
      <Title>
        {intl.formatMessage({
          id: 'contactInfoPage.contactInfoHeader',
          defaultMessage: 'Contact Information',
        })}
      </Title>
      <Separator />
      <FormContainer>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            {renderFormFields()}
            <Separator />
            {renderActionButtons()}
          </form>
        </FormProvider>
      </FormContainer>
    </div>
  );
};

export default ContactInformationForm;
