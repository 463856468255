// @flow

import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
// $FlowFixMe
import { Close as IconClose } from '@mui/icons-material';
import useSnack from 'hooks/useSnack';

const styles = {
  error: {
    background: '#C92428',
  },
  success: {
    background: '#40A3E0',
  },
};

const Snack = (): ?React$Element<any> => {
  const [snack, , resetSnack] = useSnack();

  if (!snack) {
    return null;
  }

  const { open, message, success } = snack;
  const background = success ? 'success' : 'error';
  return (
    <Snackbar
      data-testid="snack-root"
      sx={styles[background]}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message={message}
      onClose={resetSnack}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={resetSnack}
        >
          <IconClose fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default Snack;
