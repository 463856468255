const validateEmail = (value) => {
  const expr =
    /^[a-zA-Z0-9.!#$%&\\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  if (value && !expr.test(value)) {
    return { message: 'This value should be a valid email address.' };
  }
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export { validateEmail };
