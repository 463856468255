// @flow
import React from 'react';
import {
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { useIntl } from 'react-intl';
// $FlowFixMe
import { ArrowRight as IconArrowRight } from '@mui/icons-material';

const styles = {
  root: {
    flexDirection: 'row-reverse',
    '& .MuiButton-root:hover': {
      background: 'transparent',
    },
    '& .MuiButton-root': {
      cursor: 'auto',
    },
  },
  details: {
    paddingLeft: (theme: Object) => theme.spacing(13),
    paddingRight: (theme: Object) => theme.spacing(13),
    display: 'flex',
    flexDirection: 'column',
  },
  findingsTableWrapper: {
    border: '1px solid #CCCCCC',
    '& div:last-child': {
      borderBottom: 'none',
    },
  },
  textInstanceKey: {
    color: '#7F7F7F',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    marginRight: '8px',
  },
  textInstanceValue: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  findingItem: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #CCCCCC',
    alignItems: 'center',
  },
  buttonFindings: {
    textTransform: 'uppercase',
    borderRadius: '2px',
    height: 'fit-content',
    width: 80,
    fontWeight: 'bold',
    marginRight: (theme: Object) => theme.spacing(2),
  },
  criticalButton: {
    border: '1px solid #C92428',
    color: '#C92428',
  },
  majorButton: {
    border: '1px solid #FFA497',
    color: '#FFA497',
  },
  mediumButton: {
    border: '1px solid #FFD674',
    color: '#FFD674',
  },
  lowButton: {
    color: '#40A3E0',
    border: '1px solid #40A3E0',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

type TypeAnalysisItem = {
  rule: {
    conditions: Array<any>,
    description: string,
    identifier: string,
    name: string,
    objecttype: string,
    processing: string,
    processpartitions: boolean,
    severity: number,
  },
  matched: boolean,
};

type TypeAnalysis = {
  analysisItems: Array<TypeAnalysisItem>,
  fileName: string,
  stats: Object,
};

export type TypeOfFile = {
  instanceName: string,
  adcFormFactor: string,
  adcLicense: string,
  fileName: string,
  analysis: TypeAnalysis,
};

type Props = {|
  data: ?TypeOfFile,
|};

const FlexRow = styled('div')(({ theme }) => ({
  ...styles.flexRow,
  marginBottom: theme.spacing(1),
}));
const FindingsTableWrapper = styled('div')(() => styles.findingsTableWrapper);
const FindingItem = styled('div')(({ theme }) => ({
  ...styles.findingItem,
  padding: theme.spacing(1),
}));

const ReviewAccordionItem = (props: Props): ?React$Element<any> => {
  const intl = useIntl();
  const { data } = props;

  if (!data) {
    return null;
  }

  let criticalItems: Array<TypeAnalysisItem> = [];
  let majorItems: Array<TypeAnalysisItem> = [];
  let mediumItems: Array<TypeAnalysisItem> = [];
  let lowItems: Array<TypeAnalysisItem> = [];

  const { adcFormFactor, adcLicense, analysis, instanceName } = data;
  if (!analysis) {
    return null;
  }

  const { analysisItems } = analysis;

  if (analysisItems) {
    criticalItems = analysisItems.filter((x) => x.rule.severity === 1);
    majorItems = analysisItems.filter((x) => x.rule.severity === 2);
    mediumItems = analysisItems.filter((x) => x.rule.severity === 3);
    lowItems = analysisItems.filter(
      (x) => x.rule.severity === 4 || x.rule.severity === 5,
    );
  }

  return (
    <Accordion data-testid="accordion-root" sx={styles.root}>
      <AccordionSummary
        expandIcon={<IconArrowRight />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={styles.heading}>
          {`${intl.formatMessage({
            id: 'routes.review.instance',
            defaultMessage: 'Instance',
          })} ${instanceName}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        data-testid="review-accordion-details"
        sx={styles.details}
      >
        <FlexRow>
          <Typography sx={styles.textInstanceKey}>
            {intl.formatMessage({
              id: 'routes.review.formFactor',
              defaultMessage: 'Form Factor:',
            })}
          </Typography>
          <Typography sx={styles.textInstanceValue}>{adcFormFactor}</Typography>
        </FlexRow>
        <FlexRow>
          <Typography sx={styles.textInstanceKey}>
            {intl.formatMessage({
              id: 'routes.review.license',
              defaultMessage: 'License:',
            })}
          </Typography>
          <Typography sx={styles.textInstanceValue}>{adcLicense}</Typography>
        </FlexRow>

        <Typography paragraph sx={styles.textInstanceKey}>
          {intl.formatMessage({
            id: 'routes.review.findings',
            defaultMessage: 'Findings:',
          })}
        </Typography>
        <FindingsTableWrapper>
          {criticalItems?.map(({ rule }) => (
            <FindingItem
              data-testid="criticalItems"
              key={`${instanceName}_critical_${rule.name}_${rule.objecttype}`}
            >
              <Button
                disableTouchRipple
                sx={{ ...styles.buttonFindings, ...styles.criticalButton }}
              >
                Critical
              </Button>
              <Typography>{rule.name}</Typography>
            </FindingItem>
          ))}
          {majorItems?.map(({ rule }) => (
            <FindingItem
              data-testid="majorItems"
              key={`${instanceName}_major_${rule.name}_${rule.objecttype}`}
            >
              <Button
                disableTouchRipple
                sx={{ ...styles.buttonFindings, ...styles.majorButton }}
              >
                Major
              </Button>
              <Typography>{rule.name}</Typography>
            </FindingItem>
          ))}
          {mediumItems?.map(({ rule }) => (
            <FindingItem
              data-testid="mediumItems"
              key={`${instanceName}_medium_${rule.name}_${rule.objecttype}`}
            >
              <Button
                disableTouchRipple
                sx={{ ...styles.buttonFindings, ...styles.mediumButton }}
              >
                Medium
              </Button>
              <Typography>{rule.name}</Typography>
            </FindingItem>
          ))}
          {lowItems?.map(({ rule }) => (
            <FindingItem
              data-testid="lowItems"
              key={`${instanceName}_low_${rule.name}_${rule.objecttype}`}
            >
              <Button
                disableTouchRipple
                sx={{ ...styles.buttonFindings, ...styles.lowButton }}
              >
                Low
              </Button>
              <Typography>{rule.name}</Typography>
            </FindingItem>
          ))}
        </FindingsTableWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReviewAccordionItem;
