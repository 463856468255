// @flow

import React from 'react';
import type { Node } from 'react';
import { Dialog } from '@mui/material';
import VideoDescription from './VideoDescription';
import ReviewWarning from './ReviewWarning';

export type ChildProps = {|
  handleClose: Function,
  callback: Function,
|};

const modalRoadmap = {
  VIDEODESCRIPTION: {
    Component: (props: ChildProps): Node => <VideoDescription {...props} />,
  },
  REVIEWWARNING: {
    Component: (props: ChildProps): Node => <ReviewWarning {...props} />,
  },
};

export type TypeModal = 'VIDEODESCRIPTION' | 'REVIEWWARNING';

export const initialModalState = {
  isOpen: false,
  name: null,
  handleClose: () => {},
  callback: () => {},
};

export type ModalProps = {|
  isOpen: boolean,
  name: ?TypeModal,
  handleClose: Function,
  callback: ?Function,
|};

const ModalRoot = (props: ModalProps): ?React$Element<any> => {
  const { isOpen, handleClose, name, callback } = props;
  if (!name) return null;
  const ChildToRender = modalRoadmap[name].Component;
  return (
    <Dialog
      vatiant="inherit"
      data-testid="modal-root"
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ChildToRender callback={callback} handleClose={handleClose} />
    </Dialog>
  );
};

export default ModalRoot;
