// @flow

import { Tooltip, styled } from '@mui/material';
import React from 'react';

const styles = {
  root: {
    maxWidth: '120px',
    minWidth: '75px',
    height: '32px',
    borderRadius: '2px',
    textAlign: 'center',
  },
  label: {
    fontSize: '11px',
    lineHeight: '31px',
    fontWeight: 400,
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  analysing: {
    backgroundColor: '#FFD674',
    border: '1px solid #FFD674',
  },
  uploaded: {
    backgroundColor: '#CCC',
    border: '1px solid #CCC',
  },
  finished: {
    backgroundColor: '#008445',
    border: '1px solid #008445',
  },
  invalid: {
    backgroundColor: '#C92428',
    border: '1px solid #C92428',
  },
};

type Props = {|
  status: 'analysing' | 'uploaded' | 'finished' | 'invalid',
  text: string,
  tooltipText?: string,
|};

const Root = styled('div')(() => {});
const Label = styled('span')(() => styles.label);

const ConfigStatus = (props: Props): React$Element<any> => {
  const { status, text, tooltipText } = props;

  return (
    <Tooltip title={tooltipText} arrow placement="top">
      <Root
        data-testid="config-status"
        sx={{ ...styles.root, ...styles[status] }}
      >
        <Label>{text}</Label>
      </Root>
    </Tooltip>
  );
};

ConfigStatus.defaultProps = {
  tooltipText: '',
};

export default ConfigStatus;
