/* istanbul ignore file */

import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#0069AA',
    },
    type: 'light',
    background: {
      default: '#fafafa',
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    fontSize: 13,
    body2: {
      fontSize: 11,
      lineHeight: '14px',
      color: '#000000',
      fontWeight: 'normal',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
          width: 120,
          textTransform: 'none',
          borderRadius: '2px',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17.02px',
          textAlign: 'center',
        },
        outlinedPrimary: {
          color: '#505050',
          border: '1px solid #505050',
          borderRadius: '2px',
          '&:hover': {
            border: '1px solid #505050',
            borderRadius: '2px',
          },
        },
        containedPrimary: {
          backgroundColor: '#0069AA',
          color: '#ffffff',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#0069AA',
          },
        },
        containedSecondary: {
          backgroundColor: '#505050',
          color: '#ffffff',
          border: '1px solid #505050',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#505050',
          },
        },
        text: {
          background: 'none',
          borderRadius: '2px',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17.02px',
          textAlign: 'center',
          border: '1px solid #505050',
          height: 48,
          width: 120,
          color: '#505050',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: 240,
          height: 48,
          background: '#FFFFFF',
          border: '1px solid #757575',
          borderRadius: 2,
          '&$error': {
            border: '1px solid #C92428',
          },
        },
        input: {
          paddingLeft: '16px',
          color: '#000000',
          '&::placeholder': {
            lineHeight: '17px',
            fontWeight: 400,
            color: '#000000',
            fontSize: '13px',
          },
        },
        multiline: {
          height: 'auto',
        },
        inputMultiline: {
          margin: '10px 0 0 16px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '17px',
          lineHeight: '17px',
          fontWeight: 400,
          color: '#000000',
          '&$error': {
            color: '#C92428',
          },
        },
        asterisk: {
          '&$error': {
            color: '#C92428',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#000000',
          '&$error': {
            color: '#C92428',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '0 24px 32px 0',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0 8px 0 0',
        },
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
      styleOverrides: {
        body1: {
          fontSize: 13,
          lineHeight: '17px',
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
        h6: {
          fontSize: 16,
          lineHeight: '21px',
          fontFamily: '"Arrow Display"',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        paragraph: {
          fontSize: 11,
          fontWeight: 400,
          lineHeight: '14px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: 48,
          background: '#FFFFFF',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          margin: 'auto 0',
          fontSize: 11,
          lineHeight: '14px',
          textDecoration: 'none',
          color: '#3f51b5',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflow: 'hidden',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: { padding: '64px 48px 24px' },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px 48px 40px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0px 48px 64px',
          justifyContent: 'flex-start',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
        },
        expandIcon: {
          marginRight: 8,
          '&&.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
  },
});

export default mainTheme;
