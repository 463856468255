// @flow
import React from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  styled,
} from '@mui/material';

type FormFieldSelectProps = {
  field: Object,
  onChange: Function,
  value: string,
  error: any,
  styles?: Object,
  renderLabel?: boolean,
  disabled?: boolean,
  fullWidth?: boolean,
  testId?: string,
};

const Placeholder = styled('div')(() => {});

const FormFieldSelect = ({
  styles,
  field,
  onChange,
  value,
  error,
  renderLabel,
  disabled,
  fullWidth,
  testId,
}: FormFieldSelectProps): React$Element<any> => {
  const { options, required, InputLabelProps, InputProps, label, placeholder } =
    field;

  const getSelectOptions = () =>
    options && [
      options.map((option) => (
        <MenuItem
          data-testid={option}
          key={option.value || option}
          value={option.value || option}
        >
          {option.label || option}
        </MenuItem>
      )),
    ];

  return (
    <FormControl
      required={required}
      sx={styles && styles.selectForm}
      fullWidth={fullWidth}
      variant="standard"
      error={error && Boolean(error)}
    >
      {renderLabel ? (
        <InputLabel
          {...InputLabelProps}
          sx={
            disabled
              ? styles && styles.selectInputDisabledLabel
              : styles && styles.selectInputLabel
          }
        >
          {label}
        </InputLabel>
      ) : null}
      <Select
        {...InputProps}
        disabled={disabled}
        onChange={onChange}
        value={value}
        input={
          <Input sx={styles && styles.selectInput} fullWidth={fullWidth} />
        }
        inputProps={{
          'data-testid': testId,
        }}
        displayEmpty
        renderValue={
          value !== ''
            ? undefined
            : () => (
                <Placeholder sx={styles && styles.selectPlaceholder}>
                  {placeholder}
                </Placeholder>
              )
        }
      >
        {getSelectOptions()}
      </Select>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

FormFieldSelect.defaultProps = {
  styles: {},
  renderLabel: true,
  disabled: false,
  fullWidth: false,
  testId: 'select-form',
};

export default FormFieldSelect;
