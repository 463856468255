// @flow
import React from 'react';
import { TextField } from '@mui/material';

type FormFieldTextProps = {|
  field: Object,
  onChange: Function,
  value: string,
  error: any,
  type?: string,
  styles?: Object,
  endAdornment?: Node,
  disabled?: boolean,
  fullWidth?: boolean,
  testId?: string,
|};

const FormFieldText = (props: FormFieldTextProps): React$Element<any> => {
  const { styles, field, error, endAdornment, testId, ...otherProps } = props;
  const { InputProps, multiline, validator, errorMessage, ...otherFieldProps } =
    field;
  const inputProps = {
    inputProps: { 'data-testid': testId },
    InputProps: {
      ...InputProps,
      sx: styles && styles.textFieldInput,
      endAdornment,
    },
  };

  return (
    <TextField
      {...otherFieldProps}
      {...otherProps}
      {...inputProps}
      error={error && Boolean(error)}
      helperText={error ? error.message : null}
      sx={
        multiline
          ? styles && styles.textFormFieldMultiLine
          : styles && styles.textFormField
      }
      variant="standard"
    />
  );
};

FormFieldText.defaultProps = {
  type: '',
  styles: {},
  endAdornment: null,
  disabled: false,
  fullWidth: false,
  testId: 'text-field',
};

export default FormFieldText;
