import { validateEmail } from 'utils/validators';
import { breadCrumbsConfigStatus } from './globalConsts';

const getBreadCrumbsConfig = (intl) => [
  {
    key: 'contactInfo',
    label: intl.formatMessage({
      id: 'gatherDataBreadcrumb.contactInfo',
      defaultMessage: 'Contact information',
    }),
    number: '1',
    status: breadCrumbsConfigStatus.activeStatus,
  },
  {
    key: 'configsSummary',
    label: intl.formatMessage({
      id: 'gatherDataBreadcrumb.configsSummary',
      defaultMessage: 'Citrix ADC Configs (Summary)',
    }),
    number: '2',
    status: breadCrumbsConfigStatus.inactiveStatus,
  },
];

const formFieldTypes = {
  inputFieldType: 'input',
  checkboxFieldType: 'checkbox',
  selectFieldType: 'select',
};

const countryOptions = [
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Kingdom', label: 'United Kingdom' },
];

const getFormFieldsConfig = (intl) => [
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-companyName',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.companyName',
        defaultMessage: 'Company Name',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.companyName',
        defaultMessage: 'Arrow Xpand',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.companyName',
        defaultMessage: 'Company name is required',
      }),
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-addressLine1',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.addressLine1',
        defaultMessage: 'Address line 1',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.addressLine1',
        defaultMessage: 'Address line 1',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.addressLine1',
        defaultMessage: 'Address line 1 is required',
      }),
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-addressLine2',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.addressLine2',
        defaultMessage: 'Address line 2',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.addressLine2',
        defaultMessage: 'Address line 2',
      }),
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.selectFieldType,
      key: 'contactInfo-country',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.country',
        defaultMessage: 'Country',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.country',
        defaultMessage: 'Choose',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.country',
        defaultMessage: 'Country is required',
      }),
      options: countryOptions,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-city',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.city',
        defaultMessage: 'City',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.city',
        defaultMessage: 'City',
      }),
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-zipCode',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.zipCode',
        defaultMessage: 'Zip-Code',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.zipCode',
        defaultMessage: 'Zip-Code',
      }),
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
  ],
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-lastName',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.lastName',
        defaultMessage: 'Last Name',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.lastName',
        defaultMessage: 'Last Name',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.lastName',
        defaultMessage: 'Last Name is required',
      }),
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-firstName',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.firstName',
        defaultMessage: 'First Name',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.firstName',
        defaultMessage: 'First Name',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.firstName',
        defaultMessage: 'First Name is required',
      }),
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'contactInfo-email',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.email',
        defaultMessage: 'E-mail',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.email',
        defaultMessage: 'E-mail',
      }),
      required: true,
      errorMessage: intl.formatMessage({
        id: 'contactInfoFormField.error.email',
        defaultMessage: 'E-mail is required',
      }),
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
      validator: validateEmail,
    },
  ],
  [
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-name',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.partnerName',
        defaultMessage: 'Partner Name',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.partnerName',
        defaultMessage: 'Company Name',
      }),
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
    {
      type: formFieldTypes.inputFieldType,
      key: 'partner-email',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.partnerEmail',
        defaultMessage: 'Partner E-mail',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.partnerEmail',
        defaultMessage: 'Company.name@example.com',
      }),
      required: false,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
      validator: validateEmail,
    },
    {
      type: formFieldTypes.selectFieldType,
      key: 'partner-country',
      label: intl.formatMessage({
        id: 'contactInfoFormField.title.partnerCountry',
        defaultMessage: 'Country',
      }),
      placeholder: intl.formatMessage({
        id: 'contactInfoFormField.placeholder.partnerCountry',
        defaultMessage: 'Choose',
      }),
      required: false,
      options: countryOptions,
      InputLabelProps: { shrink: true, focused: false },
      InputProps: { disableUnderline: true },
      focused: true,
    },
  ],
];

export { getBreadCrumbsConfig, getFormFieldsConfig, formFieldTypes };
