const defaultLocale = 'en';

const LF_CDR_KEY = 'cdr';

const LF_FILE_PREFIX_KEY = 'file';

const headerProps = {
  title: 'Xpand Config Discovery',
};

export const FILE_STATUS = {
  ANALYSIS: 'analysing',
  UPLOADED: 'uploaded',
  FINISHED: 'finished',
  INVALID: 'invalid',
};

export const modalNames = {
  VIDEO_DESCRIPTION: 'VIDEODESCRIPTION',
  REVIEW_WARNING: 'REVIEWWARNING',
};

const footerProps = {
  topFooter: {
    socialLinks: {
      facebook: 'https://www.facebook.com/arrowfiveyearsout',
      twitter: 'https://www.twitter.com/arrowglobal',
      linkedIn: 'https://www.linkedin.com/company/arrow-electronics',
    },
    contactText: 'CONTACT US',
    contactLink: 'https://www.arrow.com/ecs/xpand/contact-us/',
    logoLink: 'https://www.arrow.com/ecs/xpand/',
  },
  bottomFooter: {
    rightsText: '© 2021 by Arrow Electronics',
  },
};

const breadCrumbsConfigStatus = {
  activeStatus: 'active',
  inactiveStatus: 'inactive',
  doneStatus: 'done',
};

const breadCrumbSeparatorStatus = {
  activeStatus: 'active',
  inactiveStatus: 'inactive',
};

const BREADCRUMB_KEY = {
  INTRODUCTION: 'introduction',
  GATHER_DATA: 'gatherData',
  REVIEW: 'review',
};

const getBreadcrumbsLinks = (intl, id, activeBreadCrumbKey) => [
  {
    title: intl.formatMessage({
      id: 'header.breadcrumb.introduction',
      defaultMessage: 'Introduction',
    }),
    link: `/#/config-discovery-request/${id}`,
    active: activeBreadCrumbKey === BREADCRUMB_KEY.INTRODUCTION,
  },
  {
    title: intl.formatMessage({
      id: 'header.breadcrumb.gatherData',
      defaultMessage: 'Gathering Data',
    }),
    link: `/#/config-discovery-request/${id}/contact-information`,
    active: activeBreadCrumbKey === BREADCRUMB_KEY.GATHER_DATA,
  },
  {
    title: intl.formatMessage({
      id: 'header.breadcrumb.review',
      defaultMessage: 'Review',
    }),
    link: `/#/config-discovery-request/${id}/review`,
    active: activeBreadCrumbKey === BREADCRUMB_KEY.REVIEW,
  },
];

const LAYOUT_BODY = {
  DEFAULT: 'default',
  CENTERED: 'centered',
  FULL: 'full',
};

export {
  defaultLocale,
  headerProps,
  footerProps,
  LF_CDR_KEY,
  breadCrumbsConfigStatus,
  breadCrumbSeparatorStatus,
  LF_FILE_PREFIX_KEY,
  LAYOUT_BODY,
  getBreadcrumbsLinks,
  BREADCRUMB_KEY,
};
