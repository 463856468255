// @flow

import React from 'react';
import { styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import LogoArrowWorm from 'images/Arrow_Worm_black.png';
import { ArrowBackIos as IconArrowDown } from '@mui/icons-material';
import ButtonLink from 'components/ButtonLink';

const { REACT_APP_CONTACT_EMAIL } = process.env;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  arrowLogo: {
    backgroundImage: `url(${LogoArrowWorm})`,
    width: 399,
    height: 180,
  },
  text: {
    fontSize: 17,
    lineHeight: '22px',
    marginBottom: '24px',
  },
  textTitle: {
    color: '#008445',
    fontSize: 24,
    lineHeight: '32px',
    marginBottom: '40px',
  },
  arrowDown: {
    marginBottom: (theme: Object) => theme.spacing(7),
    transform: 'rotate(-90deg)',
    fill: '#008445',
  },
};

const Container = styled('div')(() => styles.container);
const ArrowLogo = styled('div')(({ theme }) => ({
  ...styles.arrowLogo,
  marginBottom: theme.spacing(6),
}));

const ReviewCompleted = (): React$Element<any> => {
  const intl = useIntl();
  return (
    <Container>
      <ArrowLogo role="img" />

      <div role="img">
        <IconArrowDown fontSize="large" sx={styles.arrowDown} />
      </div>

      <Typography role="article" variant="inherit" sx={styles.textTitle}>
        {intl.formatMessage({
          id: 'reviewCompleted.completed',
          defaultMessage: 'Completed!',
        })}
      </Typography>

      <Typography role="article" variant="inherit" sx={styles.text}>
        {intl.formatMessage({
          id: 'reviewCompleted.thanks',
          defaultMessage: 'Thanks for submitting.',
        })}
      </Typography>

      <Typography role="article" variant="inherit" sx={styles.text}>
        {intl.formatMessage({
          id: 'reviewCompleted.notify',
          defaultMessage:
            'We have been notified and you will be contacted by your partner for next steps within 1 to 2 Business days.',
        })}
      </Typography>

      <Typography role="article" variant="inherit" sx={styles.text}>
        {intl.formatMessage({
          id: 'reviewCompleted.thanksCDR',
          defaultMessage: 'Thank you for using Arrow Xpand Config Discovery',
        })}
      </Typography>

      <ButtonLink
        // $FlowFixMe
        href={`mailto:${REACT_APP_CONTACT_EMAIL}`}
        text={intl.formatMessage({
          id: 'reviewCompleted.actionContact',
          defaultMessage:
            'Please contact us if you have any problem while uploading file',
        })}
      />
    </Container>
  );
};

export default ReviewCompleted;
