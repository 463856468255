import { breadCrumbsConfigStatus } from './globalConsts';

const getConfigsTableColumns = (intl) => [
  {
    key: 'status',
    label: intl.formatMessage({
      id: 'configs.list.status',
      defaultMessage: 'Status',
    }),
    className: 'configsTableCol',
  },
  {
    key: 'fileName',
    label: intl.formatMessage({
      id: 'configs.list.fileName',
      defaultMessage: 'Configuration File',
    }),
    className: 'configsTableCol',
  },
  {
    key: 'instanceName',
    label: intl.formatMessage({
      id: 'configs.list.instanceName',
      defaultMessage: 'Instance (pair) Name',
    }),
    required: true,
    className: 'configsTableCol',
  },
  {
    key: 'adcFormFactor',
    label: intl.formatMessage({
      id: 'configs.list.adcFormFactor',
      defaultMessage: 'ADC Form Factor',
    }),
    required: true,
    className: 'configsTableCol',
  },
  {
    key: 'adcLicense',
    label: intl.formatMessage({
      id: 'configs.list.adcLicense',
      defaultMessage: 'ADC License',
    }),
    required: true,
    className: 'configsTableCol',
  },
  {
    key: 'analysis',
    label: intl.formatMessage({
      id: 'configs.list.analysis',
      defaultMessage: 'Analysis',
    }),
    className: 'configsTableCol',
  },
  {
    key: 'delete',
    className: 'configsTableColSmall',
  },
];

const getBreadCrumbsConfig = (intl) => [
  {
    key: 'contactInfo',
    label: intl.formatMessage({
      id: 'gatherDataBreadcrumb.contactInfo',
      defaultMessage: 'Contact information',
    }),
    number: '1',
    status: breadCrumbsConfigStatus.doneStatus,
  },
  {
    key: 'configsSummary',
    label: intl.formatMessage({
      id: 'gatherDataBreadcrumb.configsSummary',
      defaultMessage: 'Citrix ADC Configs (Summary)',
    }),
    number: '2',
    status: breadCrumbsConfigStatus.activeStatus,
  },
];

const ANALYSIS_ITEMS_KEY = 'analysisItems';
const CONFIG_PARSED_KEY = 'configParsed';

const formControlFieldProps = {
  InputLabelProps: { shrink: true, focused: false },
  InputProps: { disableUnderline: true },
};

const instanceNameInputConfig = {
  ...formControlFieldProps,
};

const getAdcFormFactorSelectConfig = (intl) => ({
  placeholder: intl.formatMessage({
    id: 'configs.select.placeholder',
    defaultMessage: 'Choose from list',
  }),
  options: [
    { value: 'MPX', label: 'MPX' },
    { value: 'CPX', label: 'CPX' },
    { value: 'BLX', label: 'BLX' },
    { value: 'VPX(Hypervisor)', label: 'VPX(Hypervisor)' },
    {
      value: 'VPX(Public Cloud)',
      label: 'VPX(Public Cloud)',
    },
    { value: 'VPX(SDX)', label: 'VPX(SDX)' },
  ],
  ...formControlFieldProps,
});

const getAdcLicenseSelectConfig = (intl) => ({
  placeholder: intl.formatMessage({
    id: 'configs.select.placeholder',
    defaultMessage: 'Choose from list',
  }),
  options: [
    { value: 'Standard', label: 'Standard' },
    { value: 'Advanced', label: 'Advanced' },
    { value: 'Premium', label: 'Premium' },
  ],
  ...formControlFieldProps,
});

const instanceNameKey = 'instanceName';
const adcFormFactorKey = 'adcFormFactor';
const adcLicenseKey = 'adcLicense';

export {
  getConfigsTableColumns,
  getBreadCrumbsConfig,
  CONFIG_PARSED_KEY,
  ANALYSIS_ITEMS_KEY,
  instanceNameInputConfig,
  getAdcFormFactorSelectConfig,
  getAdcLicenseSelectConfig,
  instanceNameKey,
  adcFormFactorKey,
  adcLicenseKey,
};
