// @flow
/* istanbul ignore file */
import React from 'react';

import { StyledEngineProvider, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
// $FlowFixMe
import ReactDOM from 'react-dom/client';
// $FlowFixMe
import { HashRouter as Router } from 'react-router-dom';
// $FlowFixMe
import { IntlProvider } from 'react-intl';
import { language, locale, messages } from 'translations';
// $FlowFixMe
import axios from 'axios';
import Routes from './routes';
import mainTheme from './theme';

const { REACT_APP_API_URL } = process.env;
axios.defaults.baseURL = REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));

ClassNameGenerator.configure((componentName) =>
  process.env.NODE_ENV === 'production' ? `arr${componentName}` : componentName,
);

if (root) {
  root.render(
    <IntlProvider locale={locale} messages={messages[language]}>
      <StyledEngineProvider>
        <ThemeProvider theme={mainTheme}>
          <CssBaseline />
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>,
  );
}
