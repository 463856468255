// @flow

import { styled } from '@mui/material';
import React from 'react';

const styles = {
  root: {
    borderBottom: '1px solid #CCCCCC',
    width: '100%',
  },
};

const Seprator = styled('div')(() => styles.root);

type TypeProps = {| styles: Object |};

const Separator = (props: TypeProps): React$Element<any> => {
  const { styles: propStyles } = props;

  return <Seprator sx={{ ...styles, ...propStyles }} data-testid="separator" />;
};

export default Separator;
